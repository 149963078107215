.minimal-card {
  background-color: #f5f5f5;
  height: 325px;
  max-height: max-content;
  width: 275px;
  position: relative;
  border-radius: 15px;
  box-shadow: 0 8px 8px 0 #00000010;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 8px 8px 0 #00000010;
    transform: translateY(-5px);
  }
  cursor: pointer;
  @media (max-width: 1024px) {
    width: 250px;
  }
  @media (max-width: 576px) {
    width: 180px;
    height: 280px;
  }
  @media (max-width: 390px) {
    width: 100%;
    height: 280px;
  }
  .banner-container {
    position: absolute;
    top: 150px;
    background-color: #0009;
    border-radius: 0 5px 5px 0;
    padding: 0 10px;
    color: #fff;
    @media (max-width: 576px) {
      top: 110px;
      max-width: 180px;
      width: max-content;
      padding-right: 20px;
    }
  }
  .property-thumbnail {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 576px) {
      height: 155px;
    }
    .minimal-img-top {
      border-radius: 15px 15px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
    .minimal-detail {
      width: 90%;
      margin: 12px auto;
      .container-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .minimal-price {
          color: #48e;
          font-weight: 600;
          font-size: 18px;
          margin: 0;
          @media (max-width: 576px) {
            font-size: 15px;
          }
        }
        .code-card {
          margin: 0;
          @media (max-width: 576px) {
            font-size: 12px;
          }
        }
      }

      .metros-card {
        margin: 0;
        font-size: 15px;
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
      .minimal-title {
        margin: 0;
        font-size: 15px;
        font-weight: 600;
      }
      .details-vivienda {
        display: flex;
        flex-direction: row;
        gap: 3px;
        .minimal-location {
          margin: 6px 0;
          font-size: 14px;
        }
        @media (max-width: 576px) {
          .minimal-location {
            font-size: 10px;
          }
          .minimal-title {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.card-container {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 8px 8px 0 #00000010;
  .card-body {
    border-radius: 8px;
    min-height: max-content;
    width: 100%;
    height: 100%;
    padding: 6px;
    min-height: 180px;
    max-height: 340px;
    background-color: #f8f8f8;
    @media (max-width: 768px) {
      height: max-content;
      max-height: 600px;
      padding: 10px 3px;
    }
    .imagen {
      border-radius: 5px;
      height: 100%;
      min-height: 195px;
      height: 200px;
      max-height: 270px;
    }
    .info {
      position: relative;
      .main-info {
        height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .price {
          width: max-content;
          font-size: 22px;
          font-weight: 600;
          color: #48e;
        }
        .direccion {
          font-size: 22px;
          font-weight: 600;
          word-spacing: 3px;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }
    .details {
      margin-top: 15px;
      .col-md-3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        p {
          margin: 0;
        }
      }
    }
    .codigo {
      right: -5px;
      bottom: -5px;
      position: absolute;
    }
    .type-barrio {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      width: 100%;
      p {
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .type-prop {
        height: max-content;
        display: grid;
        justify-self: center;
        background-color: #e1e5f1;
        width: max-content;
        padding: 0 6px;
        border-radius: 4px;
        p {
          margin: 0;
          @media (max-width: 768px) {
            font-size: 13px;
          }
        }
      }
    }
    .description {
      margin: 14px 0;
      width: 450px;
      padding: 0;
      p {
        margin: 0;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      @media (max-width:550px){
        width: 100%;
      }
    }
  }
}

.text-gray {
  color: #707070;
}