.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
	position: relative;
    .login-card {
        width: 400px;
        margin: auto;
        z-index: 2;
        button { width: 100%; }

        @media (max-width: 500px) {
            width: 80%;
        }
        .MuiFormControl-root {
            width: 100%;
            margin: auto;
        }
    }
    .fondo::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        pointer-events: none;
        }

    .fondo {
        position: absolute;
        top: 0;
        z-index: -1;
        left: 0;
        width: 100%;
        height: 70vh;
        background: no-repeat center center;
        background-size: cover;
    }
    .dark-bg {
        z-index: 0;
        background-color: black;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(10px);
        opacity: .5;
    }
}