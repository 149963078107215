.gallery {
    margin: auto;
    padding: 5px;
    box-shadow: 0px 0px 7px 0.1px #000000;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #eaeaea;
    border: 1px solid black;
    border-radius: 5px;
	width: 800px;
	.all-images {
		display: flex;
		overflow-x: scroll;
		align-items: center;
		.img-min {
			width: 130px;
			border-radius: 5px;
			margin: 5px;
			img {
				cursor: pointer;
				border-radius: 5px;
				max-width: 130px;
			}
		}
	}
}