@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: 600;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: 700;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: 200;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: 100;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: 800;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

$primary-color: #5d96ae;
$font-color: #333333;
$background-color: #ffffff;

.app-container {
  font-family: 'Lato', sans-serif;
  padding: 25px !important;
  @media screen and (max-width: 780px) {
    padding: 10px !important;
  }
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

body::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  outline: 1px solid slategrey;
}

.not-found {
  height: 540px;
  display: grid;
  place-items: center;
  .back {
    padding: 8px 10px;
    border-radius: 5px;
    background-color: #48e;
    color: #ffffff;
    text-decoration: none;
  }
}
