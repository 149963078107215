@import '../../global.scss';

.about-container {
  background-color: rgba(138, 177, 234, 0.2);
  padding: 100px 50px;
  @media (max-width: 1170px) {
    padding: 50px 10px;
    flex-direction: column-reverse;
    align-items: center;
  }
  .about-rsp {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    max-width: 1382px;
    margin: auto;
    @media (max-width: 1170px) {
      flex-direction: column-reverse;
      align-items: center;
    }
    .image-about {
      width: 720px;
      height: 440px;
      object-fit: cover;
      border-radius: 10px;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
    .text-about {
      width: 700px;
      padding: 20px;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and(max-width:1170px) {
        width: 100%;
        padding-left: 5px;
      }
      .title-about {
        font-size: 32px;
        font-weight: 600;
        @media (max-width: 1170px) {
          text-align: center;
        }
      }
      .description-about {
        margin-top: 15px;
        width: 500px;
        font-size: 19px;
        line-height: 30px;
        @media screen and(max-width:1170px) {
          width: 85%;
          margin: auto;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}
