@import '../../global.scss';

.propiedad_form {
    margin: auto;
    width: 70%;
    padding: 5px;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid $primary-color;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 95%;
    @media (max-width: 990px) { 
        width: 90%;
    }

    .add-image-btn {
        height: 60px;
        width: 30px;
        margin: 5px;
        &:hover {
            color: white;
        }
    }
    .images-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        margin-top: 20px;
        align-items: center;
    }
    .image-container {
        width: 100px;
        height: 100px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        margin: 5px;
        border: 1px solid $primary-color;
        border-radius: 5px;
        .delete-btn {
            position: absolute;
            font-size: 20px;
            top: 0px;
            right: 0px;
            color: red;
            cursor: pointer;
            border: none;
            background-color: rgba(255, 255, 255, 0.476);
            padding: 5px;

        }

    }
    h1 {
        font-size: 25px;
		font-weight: 500;
    }
	h3 {
        font-size: 18px;
		font-weight: 500;
		
    }
	.card-title {
		padding-bottom: 5px;
		border-bottom: 0.5px solid $primary-color;
		margin: 10px 20px;
	}

    .fields-container {
        margin: auto;
		padding: 20px;
        .fields {
			vertical-align: bottom;
            width: 30%;
            display: inline-flex;
            margin: 10px;
            @media (max-width: 990px) { 
                width: 45%;
            }
            @media (max-width: 770px) { 
                width: 90%;
            }

        }
        .button-container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 10px;
        }
        .button {
            margin: 3px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            min-width: 64px;
            padding: 6px 16px;
            border-radius: 4px;    
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: #fff;
            background-color: #1976d2;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}       
        }
    }
