@import '../../global.scss';
.main-header {
  position: relative;
  height: 400px;
  background-image: url('../../assets/header.png');
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    height: 300px;
  }
  .title-header {
    position: absolute;
    text-align: center;
    z-index: 50;
    top: 30%;
    color: #fff;
    right: 50%;
    font-weight: 700;
    font-size: 38px;
    transform: translateX(50%);
    @media (max-width: 992px) {
      top: 20%;
      width: 80%;
      margin: auto;
      font-size: 32px;
    }
  }
}
.carousel-principal {
  height: 100%;
  overflow: hidden;
  z-index: 0;
  .carousel-inner {
    height: 100%;
  }
  .opacity-principal {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 8;
  }
  .parallax {
    min-height: 70vh;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
    will-change: background-position-x;
    will-change: background-position-y;
    animation-delay: 0s;
    animation-duration: 15s;
    animation-name: panoramic;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    @media (max-width: 768px) {
      animation-duration: 35s;
      min-height: 100vh;
      background-attachment: unset;
    }
  }
  .carousel-control-next {
    cursor: url('../../assets/right_arrow.svg'), auto;
  }
  .carousel-control-prev {
    cursor: url('../../assets/left_arrow.svg'), auto;
  }
  .carousel-caption {
    inset: 0%;
    z-index: 10;
    @media screen and (min-height: 650px) {
      inset: 0%;
    }
    @media screen and (min-height: 750px) {
      inset: 10%;
    }
    @media screen and (min-height: 850px) {
      inset: 20%;
    }
    @media screen and (min-height: 1000px) {
      inset: 30%;
    }
    @media screen and (min-height: 1080px) {
      inset: 35%;
    }

    h2 {
      font-size: 42px;
      text-shadow: 1px 1px 2px rgb(0 0 0);
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 0;
      color: #fff;
      text-align: center;
      font-family: sans-serif;
      @media screen and (max-width: 992px) {
        margin: auto;
        font-size: 25px;
        position: absolute;
        bottom: -100px;
        width: initial;
      }
      @media screen and (max-width: 768px) {
        position: relative;
      }
      @media screen and (min-height: 1080px) {
        inset: 0%;
      }
    }
  }
}
.searchbox {
  background-color: rgb(237 238 239 / 95%);
  position: absolute;
  bottom: 120px;
  margin: auto;
  border-radius: 10px;
  right: 0;
  left: 0;
  width: max-content;
  @media (max-width:750px){
    bottom: 80px;
  }
  @media (max-width:550px){
    bottom: 60px;
  }
  form {
    display: flex;
    align-items: center;
    .MuiFormControl-root {
      margin: 0 15px;
      padding: 2px 5px;
      width: 200px;
      @media (max-width: 992px) {
        width: 150px;
      }
      .MuiInput-root {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .button {
      margin-left: auto;
      border: none;
      outline: none;
      padding: 4px 10px;
      width: 60px;
      height: 60px;
      border-radius: 4px;
      background-color: #48e;
      color: #fff;
    }
  }
}

.dropdown {
  display: none;
  .nav-link {
    color: #111;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

.campos {
  display: block;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
}

.quick-actions {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  bottom: 70px;
  width: max-content;
  @media (max-width: 360px) {
    display: none;
  }
  .quick-action {
    display: flex;
    flex-flow: row wrap;
    margin: 0 80px;
    justify-content: center;
    border-radius: 50px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    svg {
      font-size: 50px;
      transform: translateY(18px);
      path {
        fill: none;
        stroke: white;
        stroke-width: 20px;
      }
    }
    span {
      font-family: 'Lato';
      width: inherit;
      transform: translateY(35px);
      font-weight: 600;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      path {
        stroke-width: 25px;
      }
      span {
        font-weight: 800;
      }
    }
  }
  @media screen and (max-width: 992px) {
    top: 20px;
    bottom: unset;
    .quick-action {
      width: 50px;
      height: 50px;
      margin: 0 20px;
      svg {
        font-size: 25px;
        transform: translateY(10px);
      }
      span {
        transform: translateY(20px);
        font-size: 12px;
      }
    }
  }
}

@keyframes panoramic {
  0% {
    background-position-x: 0%;
    background-position-y: 0%;
  }
  100% {
    background-position-x: 100%;
    background-position-y: 100%;
  }
}
