@import './global.scss';

body {
  font-family: 'Poppins', sans-serif;
  background-color: $background-color;
}

.floating-wpp-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  text-decoration: none;
  &:hover {
    color: white;
    box-shadow: 2px 3px 3px rgb(96, 96, 96);
  }
  svg {
    margin-top: 16px;
    margin-left: 4px;
  }
}

