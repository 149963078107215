@import '../../global.scss';

.filtro-card {
    box-shadow: 0px 0px 2px 0px $primary-color;
    width: 100%;
    margin-bottom: 20px;

.MuiFormControl-root {
	height: calc(1.5em + 22px);
	width: 320px;
	input {
		padding: 10px;
	}
}
@media screen and (max-width: 780px) {
	.MuiSelectUnstyled-root, .MuiFormControl-root  {
		width: 280px;
		min-width: 280px!important;
	}
}
.MuiTextField-root{
	label {
		display: none;
	}
}
}