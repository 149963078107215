.img-header {
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  background-image: url(../../assets/header-props.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  position: relative;
  place-items: center;
  @media (max-width: 576px) {
    margin-bottom: 0 !important;
  }
  .background-header {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(5, 38, 88, 80%);
  }
  h1 {
    z-index: 50;
    color: #fff;
    font-weight: 600;
    margin: 0;
  }
}
.badge {
  background-color: #48e !important;
  border-radius: 8px !important;
}
.search-page {
  margin: auto;
  padding: 20px 80px;
  max-width: 1382px;
  margin: auto;
  @media (max-width: 1280px) {
    padding: 20px 10px;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding-top: 0 !important;
  }
  .row {
    margin-right: 0 !important;
  }
  .filtros-card {
    margin: 0 20px;
    border-radius: 15px;
    padding: 10px;
    background-color: #f5f5f5;
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
    .filtro-category {
      margin: 10px;
      padding-bottom: 5px;
      h5 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 10px;
        font-family: 'Gilroy';
      }
      .category-options {
        display: grid;
        .category-item {
          display: flex;
          padding: 5px;
          font-size: 0.8rem;
          font-weight: 600;
          font-family: 'Gilroy';
          font-weight: 400;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            span {
              text-decoration: none;
            }
          }

          input[type='checkbox'] {
            cursor: pointer;
            position: relative;
          }

          input[type='checkbox']:before {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            top: 0;
            left: 0;
            background-color: #ffffff;
            border: 1px solid #888;
          }

          input[type='checkbox']:checked:before {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0;
            left: 0;
            background-color: #48e;
          }

          input[type='checkbox']:checked:after {
            content: '';
            display: block;
            width: 7px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 2px;
            left: 6px;
          }
        }
        .cat-disabled {
          /* opacity: 0.5; */
          &:hover {
            text-decoration: none;
          }
        }
      }
      .ambientes-container {
        display: grid;
        margin: 10px auto;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        place-items: center;
        gap: 6px;
        .ambients {
          border: 1px solid #cfcfcf;
          border-radius: 5px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          background-color: #fff;
          cursor: pointer;
          &:hover {
            background-color: #48e;
            color: #fff;
          }
          p {
            text-align: center;
            font-weight: 600;
            margin: 0;
            padding: 10px 0;
          }
        }
        .cat-inactive {
          background-color: #48e;
          color: #fff;
          border: 1px solid #5f5f5f;
          &:hover {
            background-color: #e7e7e7;
            color: #111;
          }
        }
      }
      &:not(:last-child) {
        margin-top: 10px;
        border-bottom: 1px solid #444;
      }
    }
  }

  .pagination-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .pagination {
      margin-bottom: 0;
      margin-right: 15px;
    }
  }
  .no-propiedad {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    margin: 20px;
    padding: 10px;
    @media screen and (max-width: 1280px) {
      font-size: 22px;
    }
    @media screen and (max-width: 780px) {
      font-size: 20px;
    }
  }

  .css-1iry87w,
  .MuiSelectUnstyled-root {
    width: 90% !important;
    margin: auto !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.ambientes-container {
  display: grid;
  margin: 10px auto;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 6px;
  .ambients {
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #48e;
      color: #fff;
    }
    p {
      text-align: center;
      font-weight: 600;
      margin: 0;
      padding: 10px 0;
    }
  }
  .cat-inactive {
    background-color: #48e;
    color: #fff;
    border: 1px solid #5f5f5f;
    &:hover {
      background-color: #e7e7e7;
      color: #111;
    }
  }
}

.button-filter {
  display: none !important;
  cursor: pointer;
  &:hover {
    background-color: #d7d7d7 !important;
  }
  @media (max-width: 768px) {
    display: block !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 10px !important;
    background-color: #ededed !important;
    margin: 20px 35px !important;
    border-radius: 10px !important;
    width: max-content !important;
    gap: 10px !important;
  }
}

.search-container {
  margin: 10px 0;
  height: 50px;
  border-radius: 5px;
  background-color: #fff;
  width: 90%;
  box-shadow: 0 0 1px 1px #d7d7d7;
  margin: auto;
  .search-id {
    border-radius: 5px;
    appearance: none;
    border: none;
    outline: none;
  }
}

.container-filter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1110px !important;
  @media (max-width:886px) {
    height: max-content !important;
  }
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}
