@import '../../global.scss';

.container-services {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 50px 0;
  width: 95%;
  margin: auto;
  @media (max-width: 730px) {
    flex-direction: column;
    gap: 20px;
  }
  .container-lista {
    .title-services {
      font-size: 28px;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    .lista {
      margin-top: 20px;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 18px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      li {
        list-style: none;
        color: #444;
      }
    }
  }
}
