.container {
  max-width: 100%;
}

.card-grid {
  width: 100%;
  padding: 20px;

  .pagination-grid {
    width: 100%;
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    > * {
      width: 300px;
    }
    @media screen and (max-width: 780px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .button-agregar {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: end;
    align-items: center;

    @media screen and (max-width: 780px) {
      justify-content: center;
      margin-top: 15px;
    }
    .pagination-per-item {
      flex: none;
    }
    .pagination {
      margin: 0px;
      @media screen and (max-width: 780px) {
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .page-item {
        border: none !important;
        .page-link {
          border: none !important;
        }
      }
    }
  }
}
.grid-container {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 780px) {
    width: 90%;
  }
}
