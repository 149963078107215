@import '../../global.scss';

.w-33 {
  width: 33%;
  text-align: center;
  @media (max-width: 900px) {
    width: 100% !important;
    margin-bottom: 30px;
    .mid {
      padding-left: 0px;
    }
  }
}

.footer-info {
  display: flex;
  justify-content: space-between;
  width: 90%;
  flex-wrap: wrap;
  padding: 0 50px;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
  .separador-foot {
    display: none;
    width: 90%;
    margin: 10px 0 !important;
    @media (max-width: 768px) {
      display: block;
    }
  }
}
.contact-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
  .list-contact {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    color: #ffffff;
    &:hover {
      text-decoration: underline;
    }
    a {
      color: #ffffff;
      text-decoration: none;
      margin-left: 4px;
    }
  }
}
.footer {
  background-color: #3e78cf;
  width: 100%;
  text-align: left;
  font-family: 'Lato';
  font-weight: 500;
  font-size: 16px;
  padding: 50px 50px 10px 50px;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    padding: 50px 0px;
  }
  .copy {
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
  }
  .mid {
    padding-left: 50px;
    @media (max-width: 900px) {
      padding-left: 0px;
    }
  }
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* footer left*/

.footer .footer-left {
  width: 33%;
  padding-right: 15px;
}

.footer .about {
  line-height: 19px;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  text-align: left;
}

.footer .about span {
  display: block;
  color: $primary-color;
  font-size: 24px;
  line-height: 27px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 25px;
  text-align: left;
}

.footer .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #3e78cf;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}

/* footer center*/

.footer .footer-center {
  text-align: left;
}
.icon-text a {
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 480px) {
    padding-left: 0px;
    font-size: 14px;
  }
}
.icon-footer {
  background-color: #3e78cf;
  color: #ffffff;
  width: 24px;
  height: 24px;
  margin-right: 15px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
}

.footer .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none;
}

.footer .footer-right {
  text-align: left;
  .redes-icons {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    img {
      width: 32px;
      cursor: pointer;
    }
  }
}

.footer h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
  text-align: left;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  text-align: left;
  margin-top: 20px;
  width: 100% !important;
  text-align: center !important;
}
.logo {
  width: 350px;
  text-align: left;
  cursor: pointer;
  @media (max-width: 700px) {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .footer {
    font-size: 14px;
    .footer-info {
      flex-wrap: wrap;
    }
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  }
  .footer .footer-center i {
    margin-left: 0;
  }
}
.servicios {
  text-align: left;
  color: $primary-color;
  font-weight: bold;
}
.pages {
  font-size: 14px;
  line-height: 19px;
  padding: 0px;
  list-style: none;
}
.pages li {
  text-align: left;
  padding: 5px 0px;
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
}
.pages li:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
