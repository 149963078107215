.port-contact {
  height: 200px;
  display: grid;
  place-items: center;
  position: relative;
  background-image: url('../../assets/header-contact.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  .bg-contact {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(5, 38, 88, 80%);
  }
  h1 {
    font-weight: 600;
    z-index: 100;
  }
}
.contact-form {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 30px 0;
  gap: 50px;
  max-width: 1366px;
  @media (max-width: 1100px) {
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 990px) {
    width: 80%;
    align-items: center;
  }
  @media (max-width: 578px) {
    width: 90%;
  }
}
.title-contact {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}
.container-cards {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  .card-contact {
    min-width: 270px;
    width: 280px;
    background-color: #efefef;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    @media (max-width: 768px) {
      width: 85%;
    }
    p {
      width: 90%;
      text-align: center;
      margin: 0;
    }
  }
}
.fields {
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
}
.button-container {
  width: 350px;
  margin: 30px auto;
  @media (max-width: 768px) {
    width: 95%;
  }
  .button-contact {
    width: 100%;
    background-color: #48e;
    padding: 10px 10px;
    color: #fff;
    border: none;
    border-radius: 20px;
    position: relative;
    .icon-plane {
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }
}
.fields-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .container-inputs {
    background-color: #f5f5f5;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 2px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    label + .MuiInput-formControl {
      margin-top: 25px;
    }
    .MuiFormControl-root {
      justify-content: center;
    }
    .fields {
      appearance: none;
      margin-top: 25px;
      background-color: inherit;
      margin-left: 10px !important;
    }
    .icon-message {
      margin-bottom: 50px;
    }
    .MuiFormControl-root {
      appearance: none;
      width: 100%;
      margin: 0;
      height: 100%;
      .MuiInput-underline:before {
        border: none;
      }
    }
  }
}
.carta-contacto {
  position: relative;
  padding: 1.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  margin: 15px;
  width: 70%;
  @media (max-width: 1100px) {
    width: 40%;
  }
  @media (max-width: 880px) {
    width: 100%;
  }
  .fields-container {
    width: 95%;
    padding: 10px;
  }
}
.informacion-contacto {
  position: relative;
  padding: 1.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  margin: 15px;
  width: 30%;
  @media (max-width: 1100px) {
    width: 40%;
  }
  @media (max-width: 880px) {
    width: 100%;
  }
  .ul-info {
    list-style: none;
    text-decoration: none;
    line-height: 40px;
    padding: 0px;
    font-size: 18px;
  }
  .map-img {
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    img {
      width: 100%;
      max-height: 300px;
    }
  }
}
