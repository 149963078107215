.notifications {
  position: fixed;
  top: 1rem;
  right: 1rem;
  max-width: 25rem;
  margin-bottom: 0;
  padding-left: 0;
  z-index: 99999;
}

.list {
  list-style: none;
  li {
    margin-bottom: 5px;
  }
  &:empty {
    display: none;
  }
}
