@import '../../global.scss';

[data-rmiz-modal-overlay='hidden'] {
  background-color: rgba(74, 74, 74, 0.697) !important;
}
[data-rmiz-modal-overlay='visible'] {
  background-color: rgba(20, 20, 20, 0.726) !important;
}
[data-rmiz-btn-unzoom] {
  background-color: #c6c5c8 !important;
  color: #000 !important;
}

.props-page {
  width: 95%;
  max-width: 1382px;
  margin: auto;
  @media (max-width: 678px) {
    width: 100%;
  }
  .detalles-wrapper {
    width: 100%;
    margin: auto;
    .header-detalles {
      width: 100% !important;
      height: 100px !important;
      background-image: url(../../assets/header.png) !important;
      position: absolute;
      left: 0;
      margin-bottom: 20px;
      .index-page {
        margin: 10px 20px;
        color: #d8d8d8;
        font-size: 14px;
        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
    }
    .button-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 8px 0;
      width: 100%;
      .wsp {
        text-decoration: none;
        text-align: center;
      }
      .button-form {
        width: 100%;
        border: none;
        padding: 9px 0;
        background-color: #3e78cf;
        color: #fff;
        font-weight: 600;
        border-radius: 20px;
        position: relative;
        .icon-plane2 {
          position: absolute;
          right: 20px;
          top: 10px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .button-form2 {
        width: 100%;
        border: none;
        padding: 9px 0;
        background-color: #fff;
        border: 1px solid #3e78cf;
        border-radius: 20px;
        color: #3e78cf;
        font-weight: 600;
        position: relative;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        .icon-phone {
          position: absolute;
          left: 20px;
          top: 14px;
        }
      }
    }
    .contact-form2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 92%;
      margin: auto;
      padding: 0;
      gap: 10px;
      .form-contact {
        font-size: 22px;
        font-weight: 600;
        color: #222;
        text-align: center;
        margin: 2px;
        @media (max-width: 768px) {
          font-size: 19px;
        }
      }
      .info-form {
        font-size: 16px;
        color: #3e78cf;
        text-align: center;
        margin: 2px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .fields-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;
        .input-type {
          border-radius: 5px;
          background-color: #ffffff;
          padding: 0 8px;
          border: 1px solid #e4e4e4;
          .fields {
            padding: 0;
            appearance: none;
            background-color: inherit;
          }
          .MuiFormControl-marginNormal {
            margin: 2px;
            .MuiInput-underline:before {
              border: none;
            }
          }
        }
      }
    }
    .carta-info {
      position: relative;
      padding: 40px 1.25rem;
      background-color: #f7f7f7;
      border-radius: 12px;
      margin-bottom: 10px;
      @media screen and (max-width: 420px) {
        padding-left: 0px;
      }
    }
    .wpp {
      background-color: rgb(37, 211, 102);
      height: 48px;
      padding: 6px;
      a {
        text-decoration: none;
      }
      button {
        text-align: center;
        color: white !important;
        display: flex;
        margin: auto;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        svg {
          margin-left: 15px;
          font-size: 20px;
          margin-bottom: 4px;
        }
      }
    }
    .carta-info-titulo {
      font-weight: 600;
      text-align: center;
    }
    .inmueble {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      gap: 40px;
      @media (max-width: 1255px) {
        gap: 5px;
      }
      .barrio {
        margin: 0;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .map-arrow {
        margin: 0 10px;
        @media (max-width: 768px) {
          margin: 0 4px;
          font-size: 14px;
        }
      }
    }
    .price-vivienda {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
      .precio {
        color: #3e78cf;
        font-size: 28px;
        font-weight: 600;
      }
      .type-prop {
        background-color: #3e78cf22;
        padding: 2px 6px;
        border-radius: 5px;
      }
      @media (max-width: 768px) {
        gap: 10px;
        .precio {
          font-size: 22px;
        }
      }
    }
    .carta-info-precio {
      p {
        margin: 0;
      }
      h3 {
        font-weight: 600;
        margin: 0;
        color: $primary-color;
      }
      .share-btn {
        display: flex;
        justify-content: flex-end;
        align-items: start;
      }
    }
    .precio-mob {
      display: none;
    }
    .precio-dsk {
      display: block;
    }
    max-width: calc(100vw - 100px);
    .expensas {
      font-size: 14px;
      font-weight: lighter;
    }
    .fecha {
      display: flex;
      justify-content: end;
      font-size: 14px;
    }
    .detalles-inmueble {
      position: relative;
      .detalle-operacion {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .direccion {
      margin-top: 10px;
      font-size: 22px;
      font-weight: 600;
      @media (max-width: 768px) {
        .direccion {
          font-size: 16px !important;
        }
      }
      span {
        font-weight: normal;
      }
    }
    .descripcion {
      font-size: 15px;
      white-space: pre-wrap;
      color: #303030;
    }
    .map-container {
      margin: 40px 0;
    }
    .caracteristicas {
      margin-top: 15px;
      display: flex;
      font-size: 14px;
      align-items: center;
      flex-wrap: wrap;
      > p {
        margin-right: 15px;
        align-items: center;
      }
      svg {
        margin-right: 3px;
      }
    }
    .detalle-titulo {
      font-size: 20px;
      font-weight: 500;
      margin: 10px 0;
    }
    .lista-detalle {
      display: flex;
      margin-top: 10px;
      font-weight: 400;
      @media (max-width: 1024px) {
        flex-direction: column;
      }
      div {
        width: 33.3%;
        margin-bottom: 10px;
        border-bottom: .5px solid #e1e1e1;
        border-width: 100%;
      }
      @media screen and (max-width: 1024px) {
        div {
          width: 100%;
        }
      }
    }
    .galeria {
      margin: 50px 0;
      z-index: 200;
      height: 550px;
      position: relative;
      @media (max-width: 1366px) {
        height: 430px;
        margin-bottom: 20px;
      }
      @media (max-width: 1024px) {
        height: 380px;
      }
      @media (max-width: 768px) {
        height: 100%;
      }
      .pictures {
        position: absolute;
        left: 40px;
        bottom: 10px;
        background-color: #2228;
        padding: 5px 10px;
        color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        @media (max-width: 768px) {
          bottom: 130px;
          left: 30px;
        }
      }
      .controls {
        position: absolute;
        width: 100%;
        margin: auto;
        top: 50%;
        left: 34%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: space-around;
        pointer-events: none;
        @media (max-width: 768px) {
          justify-content: space-between;
          transform: translate(-34%, -150%);
        }
        .controls-arrow {
          width: 50px;
          height: 50px;
          color: #fff;
          border-radius: 15px;
          display: grid;
          place-items: center;
          pointer-events: all;
          background-color: #2228;
          cursor: pointer;
          font-size: 26px;
        }
      }
      @media (max-width: 768px) {
        gap: 20px;
      }
      .image-index {
        width: 100% !important;
        object-fit: cover;
        height: 100% !important;
        min-height: 380px !important;
        max-height: 550px !important;
        border-radius: 10px !important;
        @media (max-width: 1366px) {
          max-height: 430px !important;
        }
        @media (max-width: 1024px) {
          max-height: 380px !important;
        }
        @media (max-width: 768px) {
          min-height: 100% !important;
        }
      }
      .no-photo {
        width: 350px !important;
        margin: auto !important;
        object-fit: contain !important;
      }
      .thumbs-image {
        display: flex;
        flex-direction: column;
        width: 85%;
        height: 100%;
        gap: 20px;
        @media (max-width: 768px) {
          width: 100%;
          flex-direction: row;
          overflow: hidden;
        }
        .images-thumbs {
          border-radius: 10px;
          width: 100%;
          height: 170px;
          object-fit: cover;
          cursor: pointer;
          @media (max-width: 1366px) {
            height: 130px;
          }
          @media (max-width: 1024px) {
            height: 113px;
            width: 90%;
          }
          @media (max-width: 768px) {
            width: 100%;
            height: 100px;
          }
        }
      }
    }
    .informacion {
      @media (max-width: 768px) {
        padding: 0;
      }
    }
    @media screen and (max-width: 780px) {
      max-width: 100%;
      .row {
        margin-left: 0;
        margin-right: 0;
      }
      .precio-mob {
        display: block;
      }
      .precio-dsk {
        display: none;
      }
      .gallery-container {
        display: none;
      }
      .fecha {
        position: absolute;
        bottom: 5px;
        font-size: 12px;
      }
      .detalle-operacion {
        font-size: 15px;
      }
      .expensas {
        font-size: 12px;
      }
      .descripcion {
        margin-bottom: 30px;
      }
      .wpp {
        button {
          font-size: 14px;
        }
      }
    }
  }
}
.share-btn-popover {
  display: flex;
  align-items: center;
  width: 100%;
  a {
    color: $primary-color;
    font-size: 20px;
    text-decoration: none;
    margin: 10px;
    &:hover {
      color: darken($primary-color, 20%);
    }
  }
}

.map-prop {
  width: 65%;
  @media (max-width: 1100px) {
    width: 100%;
  }
}

.container-detalles-flex {
  padding-bottom: 30px;
  @media (max-width: 768px) {
    padding: 0 0 30px 0;
  }
}

@media (max-width: 578px) {
  .container-detalles-flex {
    gap: 20px;
    .col-contact {
      order: 3 !important;
    }
    .frame-map {
      order: 2 !important;
    }
  }
}

.share-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  cursor: pointer;
  padding: 5px 8px;
  color: #3e78cf;
  button {
    padding: 3px;
  }
  @media (max-width: 678px) {
    padding: 2px 2px;
    font-size: 13px;
    width: 120px;
  }
}

.share-button:hover {
  background-color: #dddddd !important;
}

.pagination-detalles {
  color: #f7f7f7;
}
.pagination-detalles:hover {
  color: #3e78cf;
}
