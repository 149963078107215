@import '../../global.scss';
body {
  padding-right: 0 !important;
  overflow: unset !important;
  background-color: #fff;
}
.body-main {
  padding: 0 100px;
  max-width: 1382px;
  margin: auto;
  .container-categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    margin: 0 auto;
    width: 60%;
    @media (max-width: 1600px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .swiper-wrapper {
      margin: 0px auto 24px auto;
      height: 60px;
      .swiper-slide {
        display: grid;
        place-items: center;
      }
    }
    .link-categorie {
      background-color: #ededed;
      padding: 8px 15px;
      /* width: 220px; */
      text-align: center;
      font-size: 18px;
      border-radius: 5px;
      color: #111;
      text-decoration: none;
      &:hover {
        background-color: #48e;
        color: #fff;
      }
    }
  }
  .category {
    margin: auto;
    max-width: calc(100vw - 20px);
    .container-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      .cat-title {
        width: max-content;
        font-family: 'Lato';
        font-weight: 600;
        color: #111;
        margin: 25px 0 25px 15px;
      }
    }
  }
  @media (max-width: 1500px) {
    padding: 20px 80px;
    .cat-title {
      font-size: 32px;
    }
  }
  @media (max-width: 1350px) {
    padding: 20px 40px;
    .cat-title {
      font-size: 30px;
    }
  }
  @media (max-width: 1200px) {
    padding: 20px 20px;
    .cat-title {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 20px 20px;
    .cat-title {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 20px 10px;
    .cat-title {
      font-size: 26px;
    }
  }
  .body-title {
    margin: auto;
    width: max-content;
    margin-bottom: 50px;
  }

  .category {
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      margin: auto;
      width: min-content;
      margin-bottom: 20px;
    }
    .category-row {
      display: flex;
      justify-content: flex-start !important;
      width: 100%;
      gap: 40px;
      .swiper-props {
        /* width: max-content !important; */
        height: 330px;
        margin: auto;
        @media (max-width: 968px) {
          height: 380px;
        }
        @media (max-width: 576px) {
          height: 300px;
        }
      }
      .swiper {
        margin-left: 0;
        padding: 10px;
      }
      .minimal-card {
        padding: 5px 0;
      }
      .minimal-card-skeleton {
        width: 160px;
        height: 160px;
        margin-right: 15px;
        @media screen and (min-width: 1000px) {
          width: 200px;
          height: 200px;
        }
        @media screen and (min-width: 1200px) {
          width: 250px;
          height: 250px;
        }
      }
    }
  }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  background-color: #999 !important;
  opacity: 1 !important;
  display: none !important;
}

.swiper-button-next,
.swiper-button-prev {
  display: flex !important;
}

/* Estilos específicos para pantallas más pequeñas */
@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #ededed !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: auto !important;
}
.swiper-button-next,
.swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-color: #48e !important;
}
