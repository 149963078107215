@import '../../global.scss';
.navBar {
  background-color: #3e78cf;
  flex-wrap: nowrap;
  padding: 2px;
  .nav-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
    a {
      color: #ededed !important;
      font-weight: 500;
    }
  }
}

.navbar-container {
  z-index: 2;
  display: flex;
  flex-wrap: nowrap;
  max-width: 95%;
  padding: 0 20px !important;
  justify-content: space-between;
  z-index: 210;
  @media (max-width: 970px) {
    width: 100% !important;
    padding: 0 !important;
  }
  .phone-nav {
    font-size: 14px;
    @media (max-width: 850px) {
      font-size: 12px;
      width: max-content !important;
      padding: 5px !important;
    }
  }
  .navbar-collapse {
    @media (max-width: 992px) {
      position: absolute;
      top: 78px;
      left: 50px;
      width: 200px;
    }
    .nav-links {
      a {
        font-weight: 500 !important;
      }
      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #3e78cf;
      }
    }
  }
  .contact-badge {
    span {
      top: 10px;
      right: 5px;
      @media (max-width: 990px) {
        display: none;
      }
    }
  }
  .divider {
    border-right: 1px solid gray;
    margin: 0 10px;
  }
  .logo {
    min-width: 120px;
    width: 100%;
    margin: 10px 0;
  }
  .navbar-collapse {
    font-size: 18px;
    flex-grow: unset;
    svg {
      display: none;
    }
    .active {
      color: $primary-color !important;
      position: relative;
    }
    .active::after {
      width: 100%;
      position: absolute;
      bottom: -11px;
      left: 0;
      content: '';
      /* border-bottom: 5px solid $primary-color; */
    }
    @media (max-width: 990px) {
      svg {
        display: block;
        color: white;
      }
      .active::after {
        width: 0px;
      }
    }
  }
  .outer-logout-btn {
    color: white;
    width: 265px;
    display: block;
    text-align: right;
    svg {
      cursor: pointer;
    }
    @media (max-width: 990px) {
      display: none;
    }
  }
}
