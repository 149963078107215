@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
body {
  margin: 0;
  font-display: auto;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.google-map {
  width: 80%;
  height: 400px;
  margin: 0 0 20px 0;
  border-radius: 10px;
}
@media screen and (max-width: 1200px) {
  .google-map {
    width: 100%;
  }
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  width: 100%;
  padding: 8px !important;
}
